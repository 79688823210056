import React from 'react';
import Layout from '@/layouts/main';
import { Mixpanel } from '@/utils';
import Customs from '@/views/customs';

export default function CustomsPage() {
  React.useEffect(() => Mixpanel.track(`View Customs Page`), []);

  return (
    <Layout
      title="Customs - Transforming mental health through the gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="customs"
    >
      <Customs />
    </Layout>
  );
}
